import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div className="dhiwise-navigation">
      <h1>Homepage</h1>
      <p className="headline">
        This project was generated By{" "}
        <a href="https://www.dhiwise.com">Dhiwise</a>. Quickly use below links
        to navigate through all pages.
      </p>
      <ul>
        <li>
          <Link to="/">Login</Link>
        </li>
        <li>
          <Link to="/accountpage">AccountPage</Link>
        </li>
        <li>
          <Link to="/forgetpassword">ForgetPassword</Link>
        </li>
        <li>
          <Link to="/signup">Signup</Link>
        </li>
        <li>
          <Link to="/upload">Upload</Link>
        </li>
        <li>
          <Link to="/galleryevents">GalleryEvents</Link>
        </li>
        <li>
          <Link to="/home1">Home1</Link>
        </li>
        <li>
          <Link to="/feedpagetwo">FeedPageTwo</Link>
        </li>
        <li>
          <Link to="/feedpage">FeedPage</Link>
        </li>
        <li>
          <Link to="/feedpageone">FeedPageOne</Link>
        </li>
        <li>
          <Link to="/feedpagethree">FeedPageThree</Link>
        </li>
        <li>
          <Link to="/galleryimgpopup">GalleryImgpopup</Link>
        </li>
        <li>
          <Link to="/feedpageimgdetails">FeedpageImgDetails</Link>
        </li>
        <li>
          <Link to="/feedpageimgpopup">FeedpageImgPopup</Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
