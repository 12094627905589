import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
const FeedPage = React.lazy(() => import("pages/FeedPage"));
const Home1 = React.lazy(() => import("pages/Home1"));
const GalleryEvents = React.lazy(() => import("pages/GalleryEvents"));
const Upload = React.lazy(() => import("pages/Upload"));
const Signup = React.lazy(() => import("pages/Signup"));
const ForgetPassword = React.lazy(() => import("pages/ForgetPassword"));
const NewPassword = React.lazy(() => import("pages/NewPassword"));
const AccountPage = React.lazy(() => import("pages/AccountPage"));
const Login = React.lazy(() => import("pages/Login"));
const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
        <Route path="/" element={<Home1 />} />
          <Route path="/Login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/accountpage" element={<AccountPage />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/galleryevents" element={<GalleryEvents />} />
          <Route path="/home1" element={<Home1 />} />
          <Route path="/feedpage" element={<FeedPage />} />
         
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
